import React, { useState, useEffect } from "react"
import { withCookies } from "react-cookie"
import logo from "../../images/logo-full.svg"
import Button from "../Button/Button"
import Container from "../Container/Container"
import FreshBud from "../imageComponents/freshBud"
import styles from "./ageGateStyles.module.scss"
const AgeGate = ({ cookies }) => {
  const [rememberMe, setRememberMe] = useState(false)
  const [ageVerified, setAgeVerified] = useState(false)
  const [ofAge, setOfAge] = useState(false)
  const [cookieLoaded, setCookieLoaded] = useState(false)
  const [isCrawler, setIsCrawler] = useState(false)

  useEffect(() => {
    const botPattern =
      "(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)"
    const re = new RegExp(botPattern, "i")
    const userAgent = navigator.userAgent
    setIsCrawler(re.test(userAgent))

    const ageVerificationCookie = cookies.get("kindbio-age-verified")
      ? true
      : false
    if (ageVerificationCookie) {
      setAgeVerified(true)
      setOfAge(true)
      setCookieLoaded(true)
    } else setCookieLoaded(true)
  }, [])

  const handleClick = e => {
    if (e.currentTarget.name === "yes") {
      if (rememberMe) {
        const d = new Date()
        const day = d.getDate()
        const month = d.getMonth()
        const year = d.getFullYear() + 1

        cookies.set("kindbio-age-verified", true, {
          path: "/",
          expires: new Date(year, month, day),
        })
      } else {
        cookies.set("kindbio-age-verified", true, {
          path: "/",
        })
      }
      setAgeVerified(true)
      setOfAge(true)
    }
    if (e.currentTarget.name === "no") {
      setAgeVerified(true)
      setOfAge(false)
    }
  }

  if (ageVerified && ofAge) {
    return null
  }
  if (ageVerified && !ofAge) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          Sorry, you are not old enough to view this content.
        </div>
      </div>
    )
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.leftWrapper}>
          <div className={styles.freshBud}>
            <FreshBud></FreshBud>
          </div>
        </div>
        <div className={styles.rightWrapper}>
          <div style={{ textAlign: "center", flex: "0" }}>
            <img src={logo} width={200} className={styles.logo}></img>
            <p style={{ margin: "30px 0" }}>
              Please verify you are 21 or older.
            </p>
            <div style={{ textAlign: "center" }}>
              <Button
                name="yes"
                style={{ margin: "0 10px" }}
                onClick={handleClick}
              >
                YES
              </Button>
              <Button
                name="no"
                style={{ margin: "0 10px" }}
                onClick={handleClick}
              >
                NO
              </Button>
            </div>
            <div style={{ marginTop: "30px", fontSize: "0.9em" }}>
              <input
                type="checkbox"
                name="remember"
                checked={rememberMe}
                onChange={e => {
                  setRememberMe(e.currentTarget.checked)
                }}
              ></input>
              <label htmlFor="remember" style={{ marginLeft: "10px" }}>
                Remember Me
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withCookies(AgeGate)
